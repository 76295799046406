<template>
  <div>
    <b-modal v-model="isValidationModal" size="lg" hide-footer title="Validation finale de la section">
      <div class="text-center">
        <p>
          Attention, en cliquant sur le bouton ci-dessous, vous confirmez <strong>avoir pris connaissance</strong>
          et <strong>validez</strong> les informations
          complétées et documents chargés <strong> par les deux parties </strong>.
        </p>
        <button
          class="gavc-btn"
          @click="$emit('validate')"
        >
          Je confirme et valide cette section
        </button>
      </div>
    </b-modal>
    <button
      v-if="withModifyButton"
      class="gavc-btn"
      @click="$emit('modify')">
        Mettre à jour vos informations
    </button>
    <button
      class="gavc-btn"
      @click="isValidationModal = true">
        Valider les informations de cette phase
    </button>
  </div>
</template>

<script>
export default {
  name: 'hasValidatedMessage',
  props: {
    withModifyButton: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isValidationModal: false
    }
  }
}
</script>
